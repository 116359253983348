<template>
    <div>
        <v-snackbar top :timeout="3000" v-model="snackbar">{{ snackbarText }}</v-snackbar>
        <v-overlay style="z-index:9999!important" class="loading-center" :value="saving">
            <lottie
                :options="defaultOptions"
                :height="300"
                :width="400"
                v-on:animCreated="handleAnimation"
            />
        </v-overlay>
        <skeleton v-if="loading"/>
        <v-container fluid>
            <v-row v-if="!loading">
                <v-col cols="12" sm="12" md="12">
                    <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        >
                        El documento seleccionado solo contiene una hoja, se realizará la actualización correpondiente a los productos encontrados en este comercio.
                    </v-alert>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                     <v-text-field
                        v-model="search"
                        clearable
                        rounded
                        flat
                        filled
                        hide-details
                        :label="`Buscar producto`"
                        prepend-inner-icon="fa-search"
                        class="d-none d-md-flex mb-5"
                        />
                </v-col>
                <v-col v-if="columns['stockActive']" class="mt-5" cols="4" sm="4" md="4">
                    <span class="font-weight-bold">Productos están disponibles si el inventario es mayor a</span> :
                </v-col>
                <v-col v-if="columns['stockActive']" cols="1" sm="1" md="1">
                    <input class="control-input control-input-number" type="number" v-model.number="minStock">
                </v-col>
               <v-col cols="12" sm="12" md="12">
                   <v-data-table
                    :headers="headers"
                    :items="products"
                    :items-per-page="15"
                    :loading="loading"
                    :search="search"
                    :sort-desc="true"
                    item-key=".key"
                    :single-expand="true"
                    :footer-props="{
                        itemsPerPageOptions: [10, 25,50,100, 250],
                    }"
                    >
                    <template v-slot:[`item.price`]="{ item }">
                        {{item.price | currency}}
                    </template>

                    <template v-slot:[`item.isAvailable`]="{ item }">
                        <v-chip
                            v-if="item.stock > minStock"
                            class="ma-2"
                            color="green"
                            text-color="white"
                            >
                            Disponible
                        </v-chip>
                        <v-chip
                            v-else
                            class="ma-2"
                            color="red"
                            text-color="white"
                            >
                            Agotado
                        </v-chip>
                    </template>

                     <template v-slot:loading>
                        <div class="ma-5" style="position:relative;">
                            <div
                            style="position:absolute; z-index:999; width:100%; height:100%; display:flex; flex-direction:column;justify-content:center; align-items:center;"
                            >
                            <lottie
                                :options="defaultOptions"
                                :height="300"
                                :width="400"
                                v-on:animCreated="handleAnimation"
                            />
                            <p
                                :class="$vuetify.theme.dark?'subtitle-1 primary--text':'subtitle-1 black--text'"
                            >Cargando Data</p>
                            </div>
                            <v-skeleton-loader
                            ref="skeleton"
                            type="table-tbody"
                            class="mx-auto d-none d-md-block"
                            ></v-skeleton-loader>
                        </div>
                    </template>
            </v-data-table>
            </v-col>
           </v-row>
        </v-container>
  </div>
</template>

<script>
import Lottie from "../../../components/Lottie"
import * as animationData from "../../../assets/ochoColor.json";
import readXlsxFile from 'read-excel-file'
import skeleton from './products-skeleton'
import { db, fb } from "@/firebase";

export default {
  name: "sections",
  props : ['file', 'businessId', 'selectedColumns'],
  components: {
    lottie: Lottie,
    skeleton
  },
  data() {
    return {
        snackbar : false,
        snackbarText: null,
        loading: true,
        animationSpeed: 1.1,
        defaultOptions: {
            animationData: animationData.default,
            loop: true,
            autoplay: true
        },
        saving: false,
        products: [],
        minStock : 3,
        search: '',
        columns : [],
        headers : [
            
        ]
    }
  },
  filters : {
    currency : function( amount ) {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "HNL",
          minimumFractionDigits: 2
        });
        return formatter.format(amount)
    },
  },

  methods: {
    handleAnimation: function(anim) {
        this.anim = anim;
        anim.setSpeed(this.animationSpeed);
    }
  },
  async mounted() {  
     this.selectedColumns.forEach(column => {
        this.columns[column.value] =  column.selectedColumn;
        this.columns[`${column.value}Active`] =  column.active;
        if ( column.active )
            this.headers.push({
                text : column.text,
                value : column.value 
            })
    })

    if (this.columns['stockActive'])
        this.headers.push({
            text: 'Disponible',
            value: 'isAvailable'
        })

    this.products = []
        await readXlsxFile(this.file, { sheet: 1 }).then((rows) => {
            rows.forEach((product, index) => {
                if(index > 0) {
                    this.products.push({
                        sku : this.columns['skuActive'] ? product[this.columns['sku']] : '',
                        name : this.columns['nameActive'] ? product[this.columns['name']] : '',
                        calories : this.columns['caloriesActive'] ? product[this.columns['calories']] : '',
                        description : this.columns['descriptionActive'] ? product[this.columns['description']] : '',
                        price : this.columns['priceActive'] ? product[this.columns['price']] : '',
                        stock : this.columns['stockActive'] ? product[this.columns['stock']] : 0,
                        adults : this.columns['adultsActive'] ? product[this.columns['adults']] : '',
                        processTime : this.columns['processTimeActive'] ? product[this.columns['processTime']] : '',
                        url : this.columns['urlActive'] ? product[this.columns['url']] : '',
                    })
                }
            })
            this.loading = false
        })
  }
}
</script>

<style scoped>
    .three-columns {
        display: grid;
        grid-template-columns: 25% 50% 25%;
    }

    .theme--light.v-list {
        background:  white!important;
    }
    .modal-height {
        max-height: 450px!important; 
        overflow-y:auto!important
    }
</style>

